:root {
  /* Color System - Brand Colors */
  --colors-brand-100: #EDF4F7;
  --colors-brand-200: rgba(255, 255, 255, 0.2);
  --colors-brand-500: #0B5369;
  --colors-brand-700: #061D27;
  --colors-brand-900: #010F16;

  /* Color System - Semantic Colors */
  --color-primary: var(--colors-brand-900);    /* #010F16 */
  --color-primary-light: var(--colors-brand-700);  /* #061D27 */
  --color-secondary: var(--colors-brand-500);   /* #0B5369 */
  --color-tertiary: var(--colors-brand-200);    /* rgba(255, 255, 255, 0.2) */
  --color-background: var(--colors-brand-100);   /* #EDF4F7 */
  
  /* Shadows */
  --shadow-sm: 0 4px 12px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 6px 12px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.2);
  
  /* Typography */
  --font-family-primary: 'Montserrat', sans-serif;
  --font-family-secondary: 'Roboto', sans-serif;
  
  /* Font Weights */
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  
  /* Font Sizes */
  --fs-xs: 0.8rem;
  --fs-sm: 0.9rem;
  --fs-base: 1rem;
  --fs-md: 1.06rem;
  --fs-lg: 1.2rem;
  --fs-xl: 1.5rem;
  
  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 12px;
  --spacing-lg: 16px;
  --spacing-xl: 24px;
  
  /* Layout */
  --border-radius: 6px;
  --border-radius-full: 50%;
  
  /* Component Defaults */
  --input-height: 56px;
  --input-border-width: 1px;
  --button-min-width: 150px;
  --button-compact-width: 120px;
  
  /* Transitions */
  --transition-speed: 0.3s;
  --transition-timing: ease-in-out;
  
  /* Z-index layers */
  --z-dropdown: 1000;
  --z-overlay: 2000;
  --z-modal: 3000;
}
