@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
  
  .ghost-card.MuiCard-root {
    display: grid;
    grid-template-columns: 3rem auto;
    align-items: center;
    grid-column-gap: 1rem;
    min-width: 100%;
    height: 6rem;
    margin-bottom: 16px;
    background-color: #f0f0f0;
    animation: pulse 1.5s infinite;
    box-shadow: none;
    border-radius: 6px;
  }
  
  .ghost-card__text {
    background-color: #e0e0e0;
    height: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
    margin-top: 0.5rem;
  }
  
  .ghost-card__square {
    background-color: #e0e0e0;
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
    margin-left: 1rem;
    padding-right: 1rem;
  }
  
  .ghost-card__content {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    padding-left: 1rem;
  }