@keyframes fadeInBlink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 1; }
}

.airline-card__logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.airline-card__rewards-logo {
  object-fit: contain;
}

.airline-card__name.MuiTypography-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: #010F16;
  text-align: left;
}

.airline-card__points {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  color: #061D28;
  text-align: right;
}

.airline-card__points--blinking {
  animation: fadeInBlink 1s infinite;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: #061D28;
  text-align: right;
  transition: color 0.5s ease, transform 0.5s ease;
}

.airline-card__credit-cards {
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: flex-end;
}

.airline-card__credit-card {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  border: 0.5px solid #0B5369;
}

.airline-card__credit-card:not(:first-child) {
  margin-left: -10px;
}

.airline-card__credit-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.airline-card__more-cards {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #061D27;
  cursor: pointer;
  margin-left: -10px;
  border: 0.5px solid #0B5369;
}

.airline-card__center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 10px;
  margin-left: -50px;
}

.airline-card__expand-icon.MuiIconButton-root {
  color: var(--color-primary);
  transition: transform var(--transition-speed) var(--transition-timing),
              color var(--transition-speed) var(--transition-timing);
  padding: 0;
  min-width: auto;
  border-radius: 0;
}

.airline-card__expand-icon.MuiIconButton-root.airline-card__expand-icon--expanded {
  transform: rotate(180deg);
}

.airline-card__expand-icon.MuiIconButton-root:hover {
  background-color: transparent;
}

.airline-card__expand-icon.MuiIconButton-root .MuiSvgIcon-root {
  font-size: 2.5rem;
}

.airline-card__top-section {
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: -16px;
  padding: 16px;
  display: flex;
  align-items: center;
  height: auto;
}

.airline-card__top-section:hover {
  background-color: #EDF4F7;
}

.airline-card__arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
}

.airline-card__arrow-container svg {
  font-size: 24px;
}

.airline-card__points-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 16px;
}

.airline-card__center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 10px;
  margin-left: -50px;
} 